import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from "../components/container";

const Regulamin = () => {
  return (
    <Layout>
      <SEO
        title="Regulamin"
        description="Regulamin"
        keywords={["regulamin"]}
      />
      <br />
      <br />
      <Container>
      <div className="container container--text">
        <h1>Regulamin świadczenia usługi Quick CRM</h1>
        <br />
        <br />
        <p>
          <center><h3>Dział I. Postanowienia ogólne</h3></center>
        </p>
        <p>Użyte w Regulaminie pojęcia oznaczają:</p>
        <p>
          <strong>Usługodawca</strong> – Questy — Wdrożenia i Wsparcie IT Sp. z
          o.o. z siedzibą w Poznaniu pod adresem: Głogowska 31/33, 60–702 Poznań,
          wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy
          Poznań – Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy
          Krajowego Rejestru Sądowego pod numerem KRS 0000403007, NIP
          7792402283, nr REGON 301976857, o kapitale zakładowym 5.000 zł.
        </p>
        <p>
          <strong>Usługobiorca</strong> – oznacza osobę prawną albo jednostkę
          organizacyjną nieposiadającą osobowości prawnej niebędącą konsumentem
          w rozumieniu przepisów powszechnie obowiązującego prawa,
          reprezentowaną przez osobę fizyczną do tego upoważnioną, która
          korzysta z Usług udostępnianych przez Questy na mocy niniejszego
          Regulaminu.
        </p>
        <p>
          <strong>Usługa</strong> – oznacza usługę świadczoną przez Questy
          poprzez wysyłanie i odbieranie danych za pomocą systemów
          teleinformatycznych, na indywidualne żądanie Usługobiorcy, bez
          jednoczesnej obecności stron, przy czym dane te są transmitowane za
          pośrednictwem sieci publicznych w rozumieniu ustawy z dnia 21 lipca
          2000 r. – Prawo telekomunikacyjne (Dz. U. Nr 73, poz. 852 z
          późniejszymi zmianami). Usługa, o której tu mowa, polega na
          udostępnieniu Systemu służącego do zarządzania relacjami z klientami
          Usługobiorcy.
        </p>
        <p>
          <strong>Użytkownik</strong> – osoba reprezentująca Usługobiorcę lub
          pracownik Usługobiorcy korzystający z Usługi, przypisany do Konta.
        </p>
        <p>
          <strong>Limit liczby użytkowników</strong> – maksymalna liczba
          Użytkowników, którzy mogą jednocześnie korzystać z Usługi.
        </p>
        <p>
          <strong>System</strong> – oprogramowanie zainstalowane na serwerach
          Usługodawcy, udostępniane Usługobiorcy na komputerze Usługobiorcy
          poprzez przeglądarkę internetową, dostępne przez protokół HTTP lub
          HTTPS po zalogowaniu przy użyciu loginu i hasła pod adresem
          przypisanym do Użytkownika, umożliwiające świadczenie Usługi.
        </p>
        <p>
          <strong>Formularz rejestracyjny</strong> – dokument elektroniczny,
          znajdujący się pod adresem internetowym
          <a href="https://quickcrm.pl"> https://quickcrm.pl</a>, którego
          wypełnienie przez Usługobiorcę stanowi jeden z warunków korzystania z
          Usług świadczonych drogą elektroniczną przez Questy.
        </p>
        <p>
          <strong>Hasło dostępu</strong> – ciąg znaków literowych, cyfrowych
          oraz znaków specjalnych, znany jedynie osobie uprawnionej do pracy w
          Systemie.
        </p>
        <p>
          <strong>Konto</strong> – dokument elektroniczny przypisany do
          Usługobiorcy, powstały po wypełnieniu Formularza.
          <br />W ramach systemu mogą funkcjonować dwa rodzaje Kont: 
          <ul>
          <li>Zarządu -
          (pełne uprawnienia, w tym do nadawania, modyfikowania i usuwania
          uprawnień Użytkowników)</li>
          <li>Pracownika (uprawnienia ograniczone
          poprzez Użytkownika mającego Konto Zarządu, w szczególności poprzez
          zdefiniowanie dostępu do wybranych danych i możliwości).</li>
          </ul>
        </p>
        <p>
          <strong>Regulamin</strong> – niniejszy dokument, określający warunki
          korzystania z Usług świadczonych przez Questy drogą elektroniczną.
        </p>
        <p>
          <strong>Opłata</strong> – wynagrodzenie wnoszone z góry przez
          Usługobiorcę za Usługę świadczoną w ustalonym okresie
          rozliczeniowym, zgodnie z ofertą Questy, 
               płatne przelewem bankowym lub za pośrednictwem usługi PayU.
        
        </p>
        <p>
          <strong>Okres rozliczeniowy</strong> – okres trwający od opłacenia
          przez Klienta Konta z wybranym planem abonamentowym do ostatniego dnia
          przewidzianego w tym planie.
        </p>
        <p>
          <strong>Ustawa</strong> – ustawa z dnia 29 sierpnia 1997 r. o ochronie
          danych osobowych (tekst jednolity: Dz.U. z 2014 r., poz. 1182 z późn.
          zm.).
        </p>
        <p>
          Podczas korzystania z usług Usługodawcy w komputerze albo urządzeniu
          przenośnym Użytkownika instalowane są pliki typu cookies. Instalacja
          plików jest konieczna do prawidłowego świadczenia usług. Szczegóły
          instalowania plików zawiera Polityka prywatności
          (<a href="https://quickcrm.pl/polityka-cookies/">https://quickcrm.pl/polityka-cookies/</a>).
        </p>
        <br />
        <br />
      <div className="container container--text">
            <center> <h3> Dział II. Rodzaje i zakres usług świadczonych w oparciu o Regulamin
         </h3></center>
         <br />
         <br />
         <p>
        <ol>
          <li>
            Questy świadczy Usługi dostępu do Systemu zawierający moduł CRM.
          </li>
          <li>
            Użytkownik może nieodpłatnie założyć konto firmowe i używać Systemu
            przez okres 30 dni od momentu utworzenia Konta w wersji demo. Po
            upłynięciu 30-dniowego okresu usługi mają charakter odpłatny. Zasady
            płatności zostały określone w Dziale V Regulaminu.
          </li>
          <li>
            Questy zastrzega sobie możliwość stosowania dodatkowych zniżek oraz
            zmiany okresu trwania umowy w przypadku wybranych klientów oraz w
            wybranych promocjach.
          </li>
          <li>
            Questy zastrzega sobie możliwość zmiany funkcjonalności Serwisu w
            dowolnym czasie, w szczególności poprzez wprowadzanie nowych funkcji
            i udogodnień dla Usługobiorców. Jeżeli wprowadzanie zmian bądź
            dokonywanie aktualizacji wiązać się będzie z czasową niedostępnością
            Usług, Questy będzie zawiadamiał o takich pracach Usługobiorców, na
            co najmniej jeden dzień przed planowanymi pracami, na adres e-mail
            podany w Formularzu Rejestracyjnym.
          </li>
        </ol>
        </p>
        <br />
        <br />
<center><h3>Dział III. Warunki realizacji usług</h3></center>
<br />
<br />
<p>
        <ol>
          <li>
            Rozpoczęcie korzystania przez Usługobiorcę z Usług o charakterze
            darmowym możliwe jest jedynie w przypadku uprzedniego zapoznania się
            i akceptacji postanowień zawartych w Regulaminie, a także po
            dokonaniu rejestracji i uzyskaniu dostępu do indywidualnego Konta.
            Rozpoczęcie korzystania z opisanych tu usług również jest
            jednoznaczne z zawarciem umowy o świadczenie usług drogą
            elektroniczną, której przedmiotem jest korzystanie z Systemu na zasadach demo. Użytkownik otrzymuje dostęp do Usługi natychmiast po rejestracji
            Konta.
          </li>
          <li>
            Rozpoczęcie korzystania przez Usługobiorcę z Usług o charakterze
            odpłatnym możliwe jest jedynie w przypadku uprzedniego zapoznania
            się i akceptacji postanowień zawartych w Regulaminie, a także po
            dokonaniu rejestracji i uzyskaniu dostępu do indywidualnego Konta.
            Ponadto dostęp do Usług, o których tu mowa, możliwy jest po
            uiszczeniu opłaty zgodnej z ofertą poprzez przelew bankowy lub za pośrednictwem usługi PayU. Spełnienie warunków opisanych w niniejszym punkcie jest
            równoznaczne z zawarciem umowy o świadczenie usług drogą
            elektroniczną, której przedmiotem jest korzystanie z Systemu,
            stosownie do wybranego planu abonamentowego. Użytkownik otrzymuje
            dostęp do Usługi w ciągu 24 godzin od zaksięgowania przelewu.
          </li>
          <li>
            Questy informuje, że nie ponosi odpowiedzialności za działania
            podejmowane przez Użytkowników w ramach Kont, które zostaną dla nich
            stworzone przez Użytkownika mającego dostęp do Konta Zarządu. Za
            takie działania odpowiedzialność ponosi Użytkownik bezpośrednio
            przed swoim przełożonym, którym może być Użytkownik posiadający
            Konto Zarządu albo osoba reprezentująca Usługobiorcę, jeśli jest to
            inna osoba niż Użytkownik posiadający Konto Zarządu.
          </li>
          <li>
            Questy nie ponosi odpowiedzialności za blokowanie przez
            administratorów serwerów pocztowych przesyłania wiadomości na adres
            e-mail wskazany przez Usługobiorcę oraz za usuwanie i blokowanie
            e-maili przez oprogramowanie zainstalowane na komputerze używanym
            przez Usługobiorcę.
          </li>
          <li>
            Questy nie ponosi odpowiedzialności za szkody poniesione przez
            Usługobiorcę spowodowane zagrożeniami występującymi w sieci
            Internet, a w szczególności włamaniami do systemu informatycznego
            Usługobiorcy, przejęciami haseł przez osoby trzecie, zainfekowaniem
            systemu informatycznego Usługobiorcy wirusami.
          </li>
          <li>
            Questy nie ponosi odpowiedzialności za skutki udostępnienia przez
            Usługobiorcę jego hasła i loginu do Systemu osobom trzecim.
          </li>
          <li>
            Questy nie ponosi odpowiedzialności za problemy w funkcjonowaniu
            Systemu, jeżeli nastąpiły one wskutek zdarzeń, których Questy przy
            zachowaniu należytej staranności nie było w stanie przewidzieć lub
            którym nie mogło zapobiec.
          </li>
          <li>
            Questy nie ponosi odpowiedzialności za przerwy w świadczeniu dostępu
            do Systemu zaistniałe z przyczyn technicznych (np. konserwacja,
            przegląd, wymiana sprzętu) lub innych, niezależnych od Questy. Ze
            względu na niezależne od Questy właściwości sieci Internet oraz
            sprzętu komputerowego, Questy nie gwarantuje nieprzerwanego i
            niezakłóconego dostępu do Systemu.
          </li>
          <li>
            Questy zastrzega sobie prawo odmówienia lub zaprzestania świadczenia
            usługi w przypadku, gdy dane Usługobiorcy wskazane w formularzu
            rejestracyjnym są nieprawdziwe albo zachodzi ryzyko, że dane te mogą
            być nieprawdziwe albo niekompletne. Questy zastrzega sobie możliwość
            żądania dostarczenia przez Usługobiorcę stosownych dokumentów
            potwierdzających prawdziwość tych danych.
          </li>
          <li>
            Usługobiorca zobowiązuje się do przestrzegania wszystkich
            postanowień Regulaminu wraz z załącznikami.
          </li>
          <li>
            Usługobiorca zobowiązuje się powstrzymać od wszelkich działań
            mogących utrudnić, bądź destabilizować funkcjonowanie Systemu lub
            korzystanie z Usług.
          </li>
          <li>
            Funkcja „Integracja z GUS” jest dostępna wyłącznie w określonych przez Questy pakietach. Questy zastrzega sobie prawo do wyłączenia tej
            funkcji w przypadku zmiany przepisów Głównego Urzędu Statystycznego
            dotyczącej pobierania informacji na podstawie NIP-u.
          </li>
          <li>
            Wymagania techniczne dotyczące korzystania z usług, o których mowa w
            Dziale II Regulaminu są następujące:
            <ul>
              <li>połączenie z siecią Internet,</li>
              <li>
                przeglądarka internetowa (o standardzie odpowiadającym aplikacji
                Internet Explorer 8 lub wyższej wersji) z włączonym wykonywaniem
                Java Script oraz zapisywaniem Cookies,
              </li>
              <li>konto pocztowe e-mail.</li>
            </ul>
          </li>
        </ol>
        </p>
         <br />
        <br />
<center><h3>DZIAŁ IV. Zawieranie i rozwiązywanie umowy</h3></center>
<br />
<br />
<p>

                <ol>
                  <li>
                    Umowa o świadczenie drogą elektroniczną Usług opisanych w
                    Regulaminie zostaje zawarta w momencie wyrażenia zgody przez
                    Usługobiorcę na rozpoczęcie świadczenia przez Questy
                    wybranej Usługi. Wyrażenie zgody następuje z chwilą
                    spełnienia przez Usługobiorcę warunków wskazanych w Dziale
                    III (bezpłatny dostęp – punkt 2 Regulaminu, płatny dostęp –
                    punkt 3 Regulaminu).
                  </li>
                  <li>
                    Hasło dostępu jest ustalone przez Usługobiorcę, a Questy nie
                    gromadzi haseł Użytkowników ani ich nie modyfikuje.
                  </li>
                  <li>
                    Umowa zawarta jest na okres minimalny okres 1 miesięca. Po
                    tym okresie umowa nie zostaje automatycznie przedłużona.
                  </li>
                  <li>
                    Umowa o świadczenie usług po upływie pierwszego miesięca
                    może zostać wypowiedziana przez którąkolwiek ze stron z
                    zachowaniem miesięcznego okresu wypowiedzenia ze skutkiem na
                    koniec miesiąca.
                  </li>
                  <li>
                    Usługodawca ma prawo wypowiedzieć umowę o świadczenie usług
                    bez zachowania terminu wypowiedzenia i zaprzestać
                    świadczenia Usług bez obowiązku zwrotu całości lub części
                    pozostałej do wykorzystania opłaty w następujących
                    przypadkach:
                  </li>
                </ol>
                <ul>
                  <li>
                    naruszenia przez Usługobiorcę istotnych postanowień
                    Regulaminu,
                  </li>
                  <li>
                    umieszczania przez Usługobiorcę treści niezgodnych z
                    obowiązującymi przepisami prawa,
                  </li>
                  <li>
                    wykorzystywania przez Usługobiorcę Usługi niezgodnie ze jej
                    przeznaczeniem.
                  </li>
                </ul>

        </p>
         <br />
        <br />
<center><h3>DZIAŁ V. Płatności</h3></center>
<br />
<br />
<p>
                <ol>
                  <li>
                    Istnieją następujące możliwości rozpoczęcia korzystania z
                    odpłatnych Usług świadczonych przez Questy: płatność metodą
                    PayU lub wybór jednego z planów abonamentowych oraz
                    opłacenie stosownej faktury pro forma, bądź też skorzystanie
                    z opcji demo, a po jej zakończeniu opłacenie faktury pro
                    forma wystawionej do wybranego planu abonamentowego.
                    Faktura, o której mowa w niniejszym punkcie, przesłana
                    będzie w formie elektronicznej na adres mailowy Usługobiorcy
                    podany w Formularzu rejestracyjnym.
                  </li>
                  <li>
                    Questy wystawi Usługobiorcy w ciągu 7 dni fakturę VAT, a
                    następnie prześle ją na adres mailowy wskazany przez
                    Usługobiorcę w Formularzu rejestracyjnym. Faktura VAT będzie
                    dostępna do pobrania w panelu
                    <a href="https://www.serwis.questy.pl"> serwis.questy.pl</a>,
                    po zalogowaniu się do konta. Konto w panelu zostaje
                    utworzone automatycznie po podaniu danych przez Użytkownika
                    w procesie płatności.
                  </li>
                  <li>
                    W przypadku nieopłacenia faktury pro forma za wybrany plan
                    abonamentowy bądź brak przelewu dokonanego z wykorzystaniem
                    płatności PayU, Usługodawca zastrzega sobie prawo do
                    zaprzestania świadczenia Usług objętych tym planem i
                    zablokowaniem dostępu do nich zarówno Użytkownikowi
                    posiadającemu Konto Zarządu jaki i pozostałym Użytkownikom
                    (Konta Użytkowników przypisane do danego Usługobiorcy).
                  </li>
                  <li>
                    W wypadku nieopłacenia kolejnego okresu abonamentowego,
                    Questy zastrzega prawo do wyłączenia systemu i a po upływie
                    30 dni do trwałego usunięcia wszystkich danych.
                  </li>
                  <li>
                    Plan abonamentowy umieszczony jest w Internecie pod adresem
                   <a href="https://quickcrm.pl/crm-cennik/">" https://quickcrm.pl/crm-cennik/.</a>
                  </li>
                </ol>

        </p>
         <br />
        <br />
<center><h3>DZIAŁ VI. Reklamacje i zwroty</h3></center>
<br />
<br />
<p>
                <ol>
                  <li>
                    Usługobiorca jest uprawniony do złożenia reklamacji, w
                    przypadku, gdy przewidziane w niniejszym Regulaminie Usługi
                    są realizowane przez Questy nieprawidłowo lub niezgodnie z
                    postanowieniami Regulaminu.
                  </li>
                  <li>
                    W przypadku uznania przez Questy reklamacji Usługi o
                    charakterze odpłatnym, Questy zwróci Usługobiorcy kwotę
                    pieniężną proporcjonalną do okresu, w którym istniała
                    przeszkoda stanowiąca podstawę do reklamacji lub zaproponuje
                    inną formę zadośćuczynienia za powstałe szkody.
                  </li>
                  <li>
                    Reklamację można złożyć w formie elektronicznej za pomocą
                    formularza kontaktowego lub pisemnej. Reklamacja powinna
                    zawierać w szczególności opis problemu będącego podstawą
                    reklamacji oraz oznaczenie Usługobiorcy (nazwa, adres, NIP
                    oraz dane osoby składającej reklamację). Reklamacja złożona
                    w formie pisemnej powinna być podpisana przez osobę
                    upoważnioną i przesłana na adres do korespondencji: Questy
                    Sp. z o.o., ul. Głogowska 31/33, 60–702 Poznań.
                  </li>
                  <li>
                    Questy rozpatruje reklamację w terminie 14 dni od daty jej
                    otrzymania w prawidłowej postaci.
                  </li>
                  <li>
                    Reklamacja może zostać zgłoszona w ciągu 30 dni, licząc od
                    momentu ujawnienia się przyczyn reklamacji.
                  </li>
                  <li>
                    Questy zastrzega sobie prawo do dostępu do Konta
                    Usługobiorcy w celu usunięcia nieprawidłowości będących
                    podstawą reklamacji.
                  </li>
                  <li>
                    Odpowiedź na reklamację wysyłana jest na adres e-mail
                    wskazany przez Usługobiorcę w formularzu rejestracyjnym.
                  </li>
                  <li>
                    Reklamacje skierowane do Questy, a dotyczące usług
                    świadczonych przez podmioty trzecie za pośrednictwem Systemu
                    (np. usługa poczty elektronicznej) nie będą rozpatrywane.
                    Takie reklamacje powinny być kierowane na adres podmiotu
                    trzeciego.
                  </li>
                  <li>
                    Questy nie będzie rozpatrywać reklamacji wynikających z
                    nieznajomości obowiązujących przepisów prawa, postanowień
                    niniejszego Regulaminu lub informacji publikowanych na
                    stronie internetowej{" "}
                    <a href="https://quickcrm.pl/">https://quickcrm.pl</a>.
                  </li>
                  <li>
                    Usługobiorca może odstąpić od Umowy w ciągu 14 dni od dnia
                    jej zawarcia bez określenia przyczyny. W takiej sytuacji
                    Questy dokonuje zwrotu opłaty i usuwa konto Usługobiorcy w
                    ciągu 14 dni od daty odstąpienia od Umowy. Usługobiorca
                    przyjmuje do wiadomości, że dostęp do zgromadzonych w
                    systemie Danych będzie utracony bezpowrotnie.
                  </li>
                </ol>
        </p>

                     <br />
        <br />
<center><h3>DZIAŁ VII. Przetwarzanie i ochrona danych</h3></center>
<br />
<br /><p>
                <ol>
                 <li>Podczas zakładania Konta użytkownik jest poproszony o podanie danych. Danymi podawanymi przy rejestracji są dane dotyczące obsługi konta w
          Systemie, tj. adres email Użytkownika i hasło. Użytkownik może podać także dobrowolnie m.in.: imię, nazwisko, telefon. </li>
          <li>Użytkownik zakładając Konto w Systemie akceptuje zgodę na przetwarzanie danych osobowych, które dobrowolnie podał w Formularzu rejestracyjnym. </li>
          <li>Powyższe dane będą przetwarzane do momentu wycofania zgody, która może zostać cofnięta przez Użytkownika poprzez wysłanie wiadomości e-mail na adres kontakt@questy.pl.</li>
          <li>Questy wykorzystuje powyższe dane w celu świadczenia kompleksowych usług, kontaktu z działem obsługi klienta i świadczenia pomocy technicznej, wysłania informacji o Systemie i informacji handlowej oraz w innych celach związanych z promocją.</li> 
                  <li>
                    Umowa o świadczenie drogą elektroniczną Usług opisanych w
                    Regulaminie stanowi jednocześnie, na mocy niniejszego
                    Regulaminu, umowę powierzenia przetwarzania danych 
                     <strong> w rozumieniu art. 31 Ustawy z dnia 29 sierpnia 1997 r. o
                      ochronie danych osobowych.
                    </strong>
                  </li>
                  <li>
                    Administratorem danych, które stanowią przedmiot umowy, o
                    której mowa w punkcie powyżej jest Usługobiorca, natomiast
                    Questy jest podmiotem przyjmującym dane do przetwarzania
                    (tzw. procesor).
                  </li>
                  <li>
                    Questy zobowiązuje się przetwarzać powierzone na podstawie
                    niniejszego regulaminu dane tylko w celu związanym z
                    realizacją Usług świadczonych Usługobiorcy i tylko w podanym
                    przez niego zakresie.
                  </li>
                  <li>
                    Questy oświadcza, że stosuje środki techniczne i
                    organizacyjne mające na celu zabezpieczenie powierzonych
                    danych osobowych stosownie do przepisów, o których mowa w
                    art. 36–39 Ustawy oraz spełnia wymagania określone w
                    przepisach, o których mowa w art. 39a Ustawy. Questy
                    opracował i wdrożył politykę bezpieczeństwa oraz instrukcję
                    zarządzania systemem informatycznym służącym do
                    przetwarzania danych, o których mowa w rozporządzeniu
                    Ministra Spraw Wewnętrznych i Administracji z dnia 29
                    kwietnia 2004 r. w sprawie dokumentacji przetwarzania danych
                    osobowych oraz warunków technicznych i organizacyjnych,
                    jakim powinny odpowiadać urządzenia i systemy informatyczne
                    służące do przetwarzania danych osobowych (Dz. U. z 2004 r.,
                    nr 100, poz. 1024).
                  </li>
                  <li>
                    Questy oświadcza, że każda osoba (np. pracownik etatowy,
                    osoba świadcząca czynności na podstawie umów
                    cywilnoprawnych, inne osoby pracujące lub świadczące usługi
                    na rzecz Questy), która zostanie dopuszczona do
                    przetwarzania powierzonych przez Usługobiorcę danych
                    osobowych zostanie zobowiązana do zachowania tych danych w
                    tajemnicy. Tajemnica ta obejmuje również wszelkie informacje
                    dotyczące sposobów zabezpieczenia powierzonych do
                    przetwarzania danych osobowych.
                  </li>
                  <li>
                    Umowa powierzenia przetwarzania danych, o której mowa w
                    niniejszym Dziale, zawierana jest na czas określony,
                    powiązany z czasem świadczenia Usług wykupionych przez
                    Usługobiorcę. Rozwiązanie umowy świadczenia drogą
                    elektroniczną Usług udostępnianych w ramach Systemu jest
                    jednoznaczne z rozwiązaniem umowy powierzenia przetwarzania
                    danych.
                  </li>
                  <li>
                    W terminie 30 dni od rozwiązania lub wygaśnięcia niniejszej
                    Umowy Usługodawca jest zobowiązany trwale usunąć z systemu
                    informatycznego wszystkie dane osobowe znajdujące się w
                    powierzonych zbiorach danych i przetwarzane na podstawie
                    niniejszego Regulaminu.
                  </li>
                  <li>
                    Na życzenie Usługobiorcy Questy dostarczy protokół
                    zniszczenia powierzonych danych. Protokół taki zostanie
                    przekazany Usługobiorcy w terminie 14 dni liczonych od daty
                    skierowania takiego życzenia do Questy.
                  </li>
                  <li>
                    Questy informuje, że w związku ze świadczeniem Usług
                    opisanych w niniejszym Regulaminie zbierane będą informacje
                    statystyczne, w szczególności dotyczące liczby aktywnych
                    Użytkowników korzystających z Systemu i powierzchni dyskowej
                    w ramach danego Konta. Powyższe nie wiąże się z
                    przetwarzaniem danych osobowych w rozumieniu Ustawy.
                  </li>
                  <li>
                    Ponadto Questy informuje, że w ramach świadczonych Usług
                    opisanych w niniejszym Regulaminie wykonywane są regularne
                    kopie zapasowe, sprawdzana jest ich przydatność poprzez ich
                    testowe odtwarzanie, a także wykonywane są próby odtwarzania
                    błędów i nieprawidłowości w działaniu Systemu zgłoszonych
                    przez Usługobiorcę.
                  </li>
                </ol>
                </p>
                  <br />
        <br />
       <br />
       <br />
       <center>
       <h3>
            DZIAŁ VIII. Zasady odpowiedzialności i ochrona praw własności
            intelektualnej
          </h3>
          </center>
          <br />
          <br />
        <p>
                <ol>
                  <li>
                    W związku ze świadczeniem Usług Questy udostępnia
                    Usługobiorcy treści chronione prawem własności
                    intelektualnej, w szczególności utwory chronione prawem
                    autorskim. Usługobiorca zobowiązany jest przestrzegać
                    przepisów prawa własności intelektualnej. Wszelkie
                    kopiowanie, wprowadzanie zmian, a także publiczne
                    odtwarzanie udostępnianych treści bez zgody Questy jest
                    zakazane, o ile nie wynika to z odrębnych umów zawartych
                    przez Usługobiorcę z Questy lub bezwzględnie obowiązujących
                    przepisów prawa.
                  </li>
                  <li>
                    Questy nie odpowiada za szkody powstałe w wyniku
                    niewłaściwego korzystania z Usługi przez Usługobiorcę. Przez
                    niewłaściwe korzystanie z Usługi należy rozumieć w
                    szczególności dostarczanie przez Usługobiorcę treści
                    nieprawdziwych, nieaktualnych, nieścisłych lub mogących w
                    jakikolwiek inny sposób wprowadzać w błąd, a także
                    dostarczanie treści o charakterze bezprawnym.
                  </li>
                </ol>
        </p>
        <br />
        <br />
        <center><h3>
          DZIAŁ IX. Przepisy końcowe</h3></center>
          <br />
          <br />
<p>
                <ol>
                  <li>
                    Questy ma prawo do opublikowania nazwy firmy i adresu strony
                    internetowej Usługobiorcy na swojej liście referencyjnej,
                    jeżeli Usługobiorca nie zgłosi w tej kwestii zastrzeżenia.
                  </li>
                  <li>
                    Regulamin jest udostępniony Usługobiorcy nieodpłatnie za
                    pośrednictwem witryny internetowej, znajdującej się pod
                    adresem internetowym https://quickcrm.pl/.
                  </li>
                  <li>
                    Questy zastrzega sobie możliwość zmiany niniejszego
                    Regulaminu. Informacje o zmianach będą publikowane na
                    stronie www na co najmniej 14 dni przed ich wejściem w
                    życie, przy czym informacja taka zostanie jednocześnie (z
                    zachowaniem powyższego terminu) przesłana na adres mailowy
                    Usługobiorcy podany podczas wypełniania Formularza
                    Rejestracyjnego. Brak zgody na zmiany ze strony Usługobiorcy
                    daje mu podstawę do jednostronnego wypowiedzenia umowy bez
                    zachowania okresu wypowiedzenia.
                  </li>
                  <li>
                    Questy informuje, że zmiany dotyczące opłat wskazanych w
                    Cenniku nie będą miały zastosowania do Usługobiorców w
                    trakcie obowiązywania ich planów abonamentowych (czyli do
                    czasu ich zakończenia). Nowa cena obowiązywać będzie dopiero
                    po zakończeniu wybranego planu abonamentowego.
                  </li>
                  <li>
                    W przypadku rozbieżności pomiędzy niniejszym Regulaminem, a
                    postanowieniami zawartymi przez Questy w drodze odrębnych
                    umów dotyczących świadczenia Usług pierwszeństwo mają
                    postanowienia tych umów.
                  </li>
                  <li>Prawem właściwym jest prawo polskie.</li>
                  <li>
                    Niniejszy Regulamin obowiązuje od dnia 21.07.2017 roku.
                  </li>
                </ol>
                </p>
      </div>
              </div>
      </Container>
    </Layout>
  );
};

export default Regulamin;
